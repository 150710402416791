import enums from '@/enums';
import BaseConstructor from '@/views/modules/_common/base.constructor';
import { deepCopy } from '@/util';

/**
 * Create a new plan
 * @class PlanConstructor
 */
export default class AppServiceConstructor extends BaseConstructor {
  name = '';

  key = '';

  pricesBasedOnCountry = false;

  costsBasedOnUsd = false;

  packageBased = false;

  packages = [];

  items = [];

  availableCountriesIso2 = [];

  constructor(operation = enums.Operation.CREATE, payload = null) {
    super(operation, payload);

    this.name = operation === enums.Operation.CREATE || !payload ? this.name : payload.name || '';
    this.key = operation === enums.Operation.CREATE || !payload ? this.key : payload.key || '';
    this.pricesBasedOnCountry = operation === enums.Operation.CREATE || !payload
      ? this.pricesBasedOnCountry : payload.pricesBasedOnCountry || false;
    this.costsBasedOnUsd = operation === enums.Operation.CREATE || !payload
      ? this.costsBasedOnUsd : payload.costsBasedOnUsd || false;
    this.packageBased = operation === enums.Operation.CREATE || !payload
      ? this.packageBased : payload.packageBased || false;
    this.packages = operation === enums.Operation.CREATE || !payload
      ? this.packages : deepCopy(payload.packages) || [];
    this.items = operation === enums.Operation.CREATE || !payload
      ? this.items : deepCopy(payload.items) || [];
    this.availableCountriesIso2 = operation === enums.Operation.CREATE || !payload
      ? this.availableCountriesIso2 : deepCopy(payload.availableCountriesIso2) || [];
  }

  generatePackages() {
    const margins = [120, 110, 100, 90, 80, 60];
    this.packages = margins.map((value) => ({
      costUsd: 0,
      costAud: 0,
      margin: value,
    }));
  }

  addItem() {
    this.items.push({
      name: `Item ${this.items.length + 1}`,
      costUsd: 0,
      costAud: 0,
      margin: 120,
      price: 0,
      firstMonthFee: 0,
      firstMonthFeeName: 'Fee name',
      firstMonthPrice: 0,
      intervals: {
        trimester: {
          active: true,
          margin: 110,
          price: 0,
        },
        semester: {
          active: true,
          margin: 100,
          price: 0,
        },
        year: {
          active: true,
          margin: 90,
          price: 0,
        },
      },
    });
  }

  deleteItem(index) {
    this.items.splice(index, 1);
  }

  setCostForPackages(cost) {
    for (let i = 0; i < this.packages.length; i += 1) {
      if (this.costsBasedOnUsd) {
        this.packages[i].costUsd = cost;
      } else {
        this.packages[i].costAud = cost;
      }
    }
  }

  packagesPayload() {
    return this.packageBased
      ? this.packages.map((p) => ({
        costUsd: p.costUsd,
        costAud: p.costAud,
        margin: p.margin,
      }))
      : [];
  }

  itemsPayload() {
    return !this.packageBased
      ? this.items.map((item) => ({
        name: item.name,
        costUsd: item.costUsd,
        costAud: item.costAud,
        margin: item.margin,
        firstMonthFee: item.firstMonthFee,
        firstMonthFeeName: item.firstMonthFeeName,
        intervals: {
          trimester: {
            active: item.intervals.trimester.active,
            margin: item.intervals.trimester.margin,
            stripePriceId: item.intervals.trimester.stripePriceId,
          },
          semester: {
            active: item.intervals.semester.active,
            margin: item.intervals.semester.margin,
            stripePriceId: item.intervals.semester.stripePriceId,
          },
          year: {
            active: item.intervals.year.active,
            margin: item.intervals.year.margin,
            stripePriceId: item.intervals.year.stripePriceId,
          },
        },
      }))
      : [];
  }

  toCreatePayload() {
    return {
      name: this.name,
      key: this.key,
      pricesBasedOnCountry: this.pricesBasedOnCountry,
      costsBasedOnUsd: this.costsBasedOnUsd,
      packageBased: this.packageBased,
      packages: this.packagesPayload(),
      items: this.itemsPayload(),
      availableCountriesIso2: this.availableCountriesIso2.map((c) => c.iso2),
    };
  }

  toEditPayload() {
    return {
      id: this.payload.id,
      ...this.toCreatePayload(),
    };
  }
}
