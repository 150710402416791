<template>
  <div class="vx-card no-shadow card-border p-3 mt-3" style="min-height: 300px">
    <div class="vx-row mb-6">
      <div class="vx-col w-1/3">
        <h3>
          {{ $t(`$AppServiceModule.${model.packageBased
          ? 'PackagesInformation' : 'ItemsInformation'}`) }}
        </h3>
      </div>
      <div class="vx-col w-1/3">
        {{ $t('$AppServiceModule.MonthlyDiscount') }}: {{ monthlyDiscount }}%
      </div>
      <div class="vx-col w-1/3">
        {{ $t('$AppServiceModule.UsdExchangeRate') }}: {{ usdExchangeRate }}
      </div>
    </div>

    <div
      v-if="model.pricesBasedOnCountry"
      class="vx-row mb-6 mt-3">
      <div class="vx-col w-full">
        <label for class="vs-input--label">
          {{ $t('$AppServiceModule.AvailableCountries') }}
        </label>
        <v-select
          ref="vSelectCountries"
          class="w-full"
          label="name"
          v-model="selectedCountryLocal"
          :multiple="false"
          :closeOnSelect="true"
          :placeholder="$t('$AppServiceModule.SelectCountry')"
          :options="countriesOptions"
          :clearable="false"
          @input="$emit('update:selectedCountry', selectedCountryLocal)">
        </v-select>
      </div>
    </div>
    <div
      v-else
      class="vx-row mb-6 mt-3">
      <div class="vx-col w-full">
        <label for class="vs-input--label">
          {{ $t('$AppServiceModule.AvailableCountries') }}
        </label>
        <v-select
          ref="vSelectCountries"
          class="w-full"
          label="name"
          v-model="model.availableCountriesIso2"
          :multiple="true"
          :closeOnSelect="false"
          :placeholder="$t('$AppServiceModule.SelectCountry')"
          :options="allCountries"
          :disabled="onlyShow"
          @input="$emit('update:selectedCountry', selectedCountryLocal)">
        </v-select>
      </div>
    </div>

    <div v-show="!model.pricesBasedOnCountry || !!selectedCountryLocal">
      <div v-if="model.packageBased">
        <div
          v-for="(option, optionIndex) in servicePackages"
          :key="optionIndex"
          class="vx-row">
          <div class="vx-col flex justify-center items-center w-1/12">
            <strong>{{ optionIndex + 1 }}</strong>
          </div>
          <div class="vx-col w-11/12">
            <div
              class="grid grid-cols-4 gap-4 mb-6"
              :class="[model.costsBasedOnUsd ? 'grid-cols-5' : 'grid-cols-4']">
              <div v-if="model.costsBasedOnUsd">
                <vs-input
                  v-model.number="option.costUsd"
                  type="number"
                  min="0"
                  :label="$t('$AppServiceModule.costXUnitUsd')"
                  icon-pack="feather"
                  icon="icon-dollar-sign"
                  icon-no-border
                  style="width: auto"
                  :readonly="onlyShow"/>
              </div>
              <div>
                <vs-input
                  v-if="model.costsBasedOnUsd"
                  type="number"
                  min="0"
                  :label="$t('$AppServiceModule.costXUnitAud')"
                  icon-pack="feather"
                  icon="icon-dollar-sign"
                  icon-no-border
                  style="width: auto"
                  :value="onlyShow ? option.costAud : calculateCost(option.costUsd, option.costAud)"
                  readonly/>
                <vs-input
                  v-else
                  v-model.number="option.costAud"
                  type="number"
                  min="0"
                  :label="$t('$AppServiceModule.costXUnitAud')"
                  icon-pack="feather"
                  icon="icon-dollar-sign"
                  icon-no-border
                  style="width: auto"
                  :readonly="onlyShow"/>
              </div>
              <div>
                <vs-input
                  v-model.number="option.margin"
                  type="number"
                  min="0"
                  :label="$t('$AppServiceModule.Margin')"
                  icon-pack="feather"
                  icon="icon-percent"
                  icon-no-border
                  style="width: auto"
                  :readonly="onlyShow"/>
              </div>
              <div>
                <vs-input
                  type="number"
                  min="0"
                  :label="$t('$AppServiceModule.PrepaidUnitPrice')"
                  icon-pack="feather"
                  icon="icon-dollar-sign"
                  icon-no-border
                  style="width: auto"
                  :value="onlyShow
          ? option.prepaidPrice
          : calculateUnitPrice(option.costUsd, option.costAud, option.margin)"
                  readonly/>
              </div>
              <div>
                <vs-input
                  type="number"
                  min="0"
                  :label="$t('$AppServiceModule.MonthlyUnitPrice')"
                  icon-pack="feather"
                  icon="icon-dollar-sign"
                  icon-no-border
                  style="width: auto"
                  :value="onlyShow
          ? option.monthlyPrice
          : calculateUnitPrice(option.costUsd, option.costAud,
              option.margin, monthlyDiscount)"
                  readonly/>
              </div>
            </div>
          </div>
          <vs-divider class="px-3"/>
        </div>
      </div>
      <div v-else>
        <div
          v-for="(item, itemIndex) in serviceItems"
          :key="itemIndex"
          class="vx-row">
          <div class="vx-col w-11/12">
            <div
              class="grid grid-cols-4 gap-4"
              :class="[model.costsBasedOnUsd ? 'grid-cols-5' : 'grid-cols-4']">
              <div>
                <vs-input
                  type="text"
                  :label="$t('$General.Name')"
                  style="width: auto"
                  v-model.number="item.name"
                  :readonly="onlyShow"/>
              </div>
              <div v-if="model.costsBasedOnUsd">
                <vs-input
                  v-model.number="item.costUsd"
                  type="number"
                  min="0"
                  :label="$t('$AppServiceModule.costXUnitUsd')"
                  icon-pack="feather"
                  icon="icon-dollar-sign"
                  icon-no-border
                  style="width: auto"
                  :readonly="onlyShow"/>
              </div>
              <div>
                <vs-input
                  v-if="model.costsBasedOnUsd || model.pricesBasedOnCountry"
                  type="number"
                  min="0"
                  :label="$t('$AppServiceModule.costXUnitAud')"
                  icon-pack="feather"
                  icon="icon-dollar-sign"
                  icon-no-border
                  style="width: auto"
                  :value="onlyShow ? item.costAud : calculateCost(item.costUsd, item.costAud)"
                  readonly/>
                <vs-input
                  v-else
                  v-model.number="item.costAud"
                  type="number"
                  min="0"
                  :label="$t('$AppServiceModule.costXUnitAud')"
                  icon-pack="feather"
                  icon="icon-dollar-sign"
                  icon-no-border
                  style="width: auto"
                  :readonly="onlyShow"/>
              </div>
              <div>
                <vs-input
                  v-model.number="item.margin"
                  type="number"
                  min="0"
                  :label="$t('$AppServiceModule.Margin')"
                  icon-pack="feather"
                  icon="icon-percent"
                  icon-no-border
                  style="width: auto"
                  :readonly="onlyShow"/>
              </div>
              <div>
                <vs-input
                  type="number"
                  min="0"
                  :label="$t('$AppServiceModule.Price')"
                  icon-pack="feather"
                  icon="icon-dollar-sign"
                  icon-no-border
                  style="width: auto"
                  :value="onlyShow
                  ? item.price
                  : calculateTotalPrice(1, item.costUsd, item.costAud, item.margin)"
                  readonly/>
              </div>
            </div>
          </div>
          <div class="vx-col flex justify-center items-center pl-0 w-1/12">
            <vs-button
              v-if="!onlyShow"
              color="danger"
              size="small"
              icon-pack="feather"
              icon="icon-trash-2"
              @click="model.deleteItem(itemIndex)">
            </vs-button>
          </div>

          <div class="vx-col w-11/12 mt-3">
            <div class="grid grid-cols-4 gap-4">
              <vs-input
                v-model="item.firstMonthFeeName"
                type="text"
                :label="$t('$AppServiceModule.FirstMonthFeeName')"
                icon-pack="feather"
                icon="icon-info"
                icon-no-border
                style="width: auto"
                :readonly="onlyShow"/>

              <vs-input
                v-model.number="item.firstMonthFee"
                type="number"
                min="0"
                :label="$t('$AppServiceModule.FirstMonthFee')"
                icon-pack="feather"
                icon="icon-dollar-sign"
                icon-no-border
                style="width: auto"
                :readonly="onlyShow"/>

              <vs-input
                type="number"
                min="0"
                :label="$t('$AppServiceModule.FirstMonthPrice')"
                icon-pack="feather"
                icon="icon-dollar-sign"
                icon-no-border
                style="width: auto"
                :value="onlyShow
                  ? item.price
                  : calculateFirstMonthPrice(1, item.costUsd, item.costAud, item.margin, item.firstMonthFee)"
                readonly/>
            </div>

            <h4 class="mt-4">{{$t('Intervals')}}</h4>

            <h5 class="mt-4">{{$t('Trimestrer')}}</h5>
          </div>

          <div class="vx-col w-11/12 mt-3">
            <div class="grid grid-cols-4 gap-10">
              <vs-input
                v-model.number="item.intervals.trimester.margin"
                type="number"
                min="0"
                :label="$t('$AppServiceModule.Margin')"
                icon-pack="feather"
                icon="icon-percent"
                icon-no-border
                style="width: auto"
                :readonly="onlyShow"
              />

              <vs-input
                type="number"
                min="0"
                :label="$t('PriceMonthlyPeriod')"
                icon-pack="feather"
                icon="icon-dollar-sign"
                icon-no-border
                style="width: auto"
                :value="calculateTotalPrice(1, item.costUsd, item.costAud, item.intervals.trimester.margin)"
                readonly
              />

              <vs-input
                type="number"
                min="0"
                :label="$t('$AppServiceModule.Price')"
                icon-pack="feather"
                icon="icon-dollar-sign"
                icon-no-border
                style="width: auto"
                :value="onlyShow
                  ? item.intervals.trimester.price
                  : calculatePricePeriod( 1, item.costUsd, item.costAud, item.intervals.trimester.margin, 3 )"
                readonly/>

              <vs-input
                type="number"
                min="0"
                :label="$t('Discount')"
                icon-pack="feather"
                icon="icon-percent"
                icon-no-border
                style="width: auto"
                :value="calculateDiscountPercentPeriod( 1, item.costUsd, item.costAud, item.margin, item.intervals.trimester.margin )"
                readonly
              />

            </div>

            <h5 class="mt-1">{{$t('Semester')}}</h5>

          </div>

          <div class="vx-col flex justify-center items-center pl-0 w-1/12">

            <vs-checkbox
              color="primary"
              v-model="item.intervals.trimester.active"
              :disabled="onlyShow"
              >{{ $t('Active') }}
            </vs-checkbox>

          </div>

          <div class="vx-col w-11/12 mt-3">
            <div class="grid grid-cols-4 gap-10">

              <vs-input
                v-model.number="item.intervals.semester.margin"
                type="number"
                min="0"
                :label="$t('$AppServiceModule.Margin')"
                icon-pack="feather"
                icon="icon-percent"
                icon-no-border
                style="width: auto"
                :readonly="onlyShow"/>

              <vs-input
                type="number"
                min="0"
                :label="$t('PriceMonthlyPeriod')"
                icon-pack="feather"
                icon="icon-dollar-sign"
                icon-no-border
                style="width: auto"
                :value="calculateTotalPrice(1, item.costUsd, item.costAud, item.intervals.semester.margin)"
                readonly
              />

              <vs-input
                type="number"
                min="0"
                :label="$t('$AppServiceModule.Price')"
                icon-pack="feather"
                icon="icon-dollar-sign"
                icon-no-border
                style="width: auto"
                :value="onlyShow
                  ? item.intervals.semester.price
                  : calculatePricePeriod( 1, item.costUsd, item.costAud, item.intervals.semester.margin, 6 )"
                readonly/>

              <vs-input
                type="number"
                min="0"
                :label="$t('Discount')"
                icon-pack="feather"
                icon="icon-percent"
                icon-no-border
                style="width: auto"
                :value="calculateDiscountPercentPeriod( 1, item.costUsd, item.costAud, item.margin, item.intervals.semester.margin )"
                readonly
              />

            </div>

            <h5>{{$t('Year')}}</h5>

          </div>

          <div class="vx-col flex justify-center items-center pl-0 w-1/12">

            <vs-checkbox
              color="primary"
              v-model="item.intervals.semester.active"
              :disabled="onlyShow"
            >{{ $t('Active') }}
            </vs-checkbox>

          </div>

          <div class="vx-col w-11/12 mt-3">
            <div class="grid grid-cols-4 gap-10">
              <vs-input
                v-model.number="item.intervals.year.margin"
                type="number"
                min="0"
                :label="$t('$AppServiceModule.Margin')"
                icon-pack="feather"
                icon="icon-percent"
                icon-no-border
                style="width: auto"
                :readonly="onlyShow"/>

              <vs-input
                type="number"
                min="0"
                :label="$t('PriceMonthlyPeriod')"
                icon-pack="feather"
                icon="icon-dollar-sign"
                icon-no-border
                style="width: auto"
                :value="calculateTotalPrice(1, item.costUsd, item.costAud, item.intervals.year.margin)"
                readonly
              />

              <vs-input
                type="number"
                min="0"
                :label="$t('$AppServiceModule.Price')"
                icon-pack="feather"
                icon="icon-dollar-sign"
                icon-no-border
                style="width: auto"
                :value="onlyShow
                  ? item.intervals.year.price
                  : calculatePricePeriod( 1, item.costUsd, item.costAud, item.intervals.year.margin, 12 )"
                readonly/>

              <vs-input
                type="number"
                min="0"
                :label="$t('Discount')"
                icon-pack="feather"
                icon="icon-percent"
                icon-no-border
                style="width: auto"
                :value="calculateDiscountPercentPeriod( 1, item.costUsd, item.costAud, item.margin, item.intervals.year.margin )"
                readonly
              />
            </div>

          </div>

          <div class="vx-col flex justify-center items-center pl-0 w-1/12">

            <vs-checkbox
              color="primary"
              v-model="item.intervals.year.active"
              :disabled="onlyShow"
            >{{ $t('Active') }}
            </vs-checkbox>

          </div>

        </div>

        <vs-divider class="px-3"/>

        <vs-button
          v-if="!onlyShow"
          class="ml-auto"
          @click="model.addItem()">
          {{ $t('$AppServiceModule.AddItem') }}
        </vs-button>
      </div>
    </div>

  </div>
</template>

<script>
import { ceil } from 'mathjs';
import allCountries from '@/assets/utils/all-countries';
import vSelect from 'vue-select';
import enums from '@/enums';
import appServiceCountryService from '@/api/modules/app-service-country.service';

/**
 * Component to create or edit app services
 *
 * @module views/modules/app-service/AppServiceListCreateOrEditPackages
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} operation - operation to make
 * @vue-prop {Object} model - model to save
 * @vue-prop {Number} monthlyDiscount - monthly discount for packages
 * @vue-prop {Number} usdExchangeRate - usd exchange rate for services
 * @vue-prop {Object[]} providerCostInfo - provider cost by countries
 * @vue-prop {boolean} onlyShow - indicate if the component if only show
 * @vue-prop {Object} selectedCountry - select country
 * @vue-data {Object} selectedCountryLocal - select country local
 * @vue-data {Object[]} allCountries - list of all countries
 * @vue-data {Object} appServiceCountry - app service country selected
 * @vue-computed {string[]} providerCostCountries - countries iso2 of provider
 * @vue-computed {Object[]} countriesOptions - countries options to select
 * @vue-computed {Object[]} servicePackages - service packages
 * @vue-computed {Object[]} serviceItems - service items
 * @vue-event {void} selectedCountryLocal - watch to get app service country
 * @vue-event {void} created - hook to map available countries iso2
 * @vue-event {void} calculateCost - calculate the cost for package or item
 * @vue-event {void} calculateUnitPrice - calculate unit price
 * @vue-event {void} calculateTotalPrice - calculate the total price
 */
export default {
  name: 'AppServiceListCreateOrEditPackages',
  i18n: {
    messages: {
      en: {
        Intervals: 'Intervals',
        Trimester: 'Trimester',
        Semester: 'Semester',
        Year: 'Year',
        PriceMonthlyPeriod: 'Price monthly(AUD)',
      },
    },
  },
  components: {
    vSelect,
  },
  props: {
    operation: {
      type: String,
      required: true,
      validator(value) {
        return [
          enums.Operation.CREATE,
          enums.Operation.EDIT,
          enums.Operation.CLONE].indexOf(value) !== -1;
      },
    },
    model: {
      type: Object,
      required: true,
    },
    monthlyDiscount: {
      type: Number,
      required: true,
    },
    usdExchangeRate: {
      type: Number,
      required: true,
    },
    providerCostInfo: {
      type: Array,
      required: true,
    },
    onlyShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedCountry: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      selectedCountryLocal: this.selectedCountry || null,
      allCountries,
      appServiceCountry: {
        packages: [],
        items: [],
      },
    };
  },
  computed: {
    providerCostCountries() {
      return this.providerCostInfo.map((p) => p.codeCountry);
    },
    countriesOptions() {
      return this.onlyShow
        ? this.model.availableCountriesIso2
        : this.allCountries.filter((c) => this.providerCostCountries.includes(c.iso2));
    },
    servicePackages() {
      return this.onlyShow && this.model.pricesBasedOnCountry
        ? this.appServiceCountry.packages
        : this.model.packages;
    },
    serviceItems() {
      return this.onlyShow && this.model.pricesBasedOnCountry
        ? this.appServiceCountry.items
        : this.model.items;
    },
  },
  watch: {
    selectedCountryLocal: {
      async handler(country) {
        if (country) {
          if (this.onlyShow) {
            const resp = await appServiceCountryService.getOneByServiceAndCountry({
              serviceId: this.model.id,
              countryIso2: country.iso2,
            });
            this.appServiceCountry = resp.data;
          } else {
            const providerInfo = this.providerCostInfo.find((p) => p.codeCountry === country.iso2);
            this.model.setCostForPackages(providerInfo.costCountry);
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.operation === this.$enums.Operation.EDIT) {
      this.model.availableCountriesIso2 = this.allCountries.filter(
        (c) => {
          const index = this.model.availableCountriesIso2.findIndex((mc) => (typeof mc === 'string' ? c.iso2 === mc : c.iso2 === mc.iso2));
          return index !== -1;
        },
      );
    }
  },
  methods: {
    calculateCost(costUsd, costAud) {
      return this.model.costsBasedOnUsd
        ? ceil(costUsd * this.usdExchangeRate, 3)
        : ceil(costAud, 3);
    },
    calculateUnitPrice(costUsd, costAud, margin, discount = 0) {
      const cost = this.calculateCost(costUsd, costAud);
      const unitPrice = Number(cost + (cost * (margin / 100)));
      const unitPriceWithDiscount = Number(unitPrice - (unitPrice * (discount / 100)));
      return ceil(unitPriceWithDiscount, 3);
    },
    calculateTotalPrice(quantity, costUsd, costAud, margin, discount = 0) {
      const unitPrice = this.calculateUnitPrice(costUsd, costAud, margin, discount);
      const totalPrice = Number(unitPrice * quantity);
      return ceil(totalPrice);
    },
    calculateFirstMonthPrice(quantity, costUsd, costAud, margin, fee, discount = 0) {
      const totalPrice = this.calculateTotalPrice(quantity, costUsd, costAud, margin, discount);
      const firstMonthlyPrice = Number(totalPrice + fee);
      return ceil(firstMonthlyPrice);
    },
    calculatePricePeriod(quantity, costUsd, costAud, margin, numberPeriods) {
      const priceBase = this.calculateTotalPrice(quantity, costUsd, costAud, margin);

      return priceBase * numberPeriods;
    },
    calculateDiscountPercentPeriod(quantity, costUsd, costAud, marginNormal, marginPeriod) {
      const priceNormal = this.calculateTotalPrice(quantity, costUsd, costAud, marginNormal);
      const priceMonthlyPeriod = this.calculateTotalPrice(quantity, costUsd, costAud, marginPeriod);

      return ceil((((priceNormal - priceMonthlyPeriod) / priceNormal) * 100), 2);
    },
  },
};
</script>
