<template>
  <common-cell-renderer-actions
    :actions="filteredActionsFromParent"
    @view="viewRecord()"
    @clone="cloneRecord()"
    @edit="editRecord()"
    @delete="confirmDeleteRecord()">
  </common-cell-renderer-actions>
</template>

<script>
import commonSingleCellRendererActions from '@/views/modules/_mixins/commonSingleCellRendererActions';
import CommonCellRendererActions from '@/views/modules/_components/cell-renderer/CommonCellRendererActions.vue';

/**
 * Cell renderer actions to single route modules
 *
 * @module views/modules/components/cell-renderer/SingleCellRendererActions
 * @author Dilan Useche <dilan8810@gmail.com>
 */
export default {
  name: 'SingleCellRendererActions',
  components: {
    CommonCellRendererActions,
  },
  mixins: [commonSingleCellRendererActions],
};
</script>
