<template>
  <div class="vx-card p-6">
    <transition name="zoom-fade">
      <app-service-list-create-or-edit
        v-if="showCreateOrEditComponent"
        :operation="operation"
        :model-payload="recordSelected"
        :entity="entity"
        @saved="savedItem"
        @close="activeModalCreateOrEdit=false">
      </app-service-list-create-or-edit>
    </transition>

    <div v-if="!showCreateOrEditComponent">
      <base-ag-grid-header
        :rows-selected-count="rowsSelectedCount"
        :filters-count="filtersAppliedCount"
        :filters-match.sync="filtersMatch"
        @reset-selection="setSelectedRowsNone()"
        @reset-filters="resetFilters()">
        <template v-slot:header-left>
          <items-per-page
            :count="count"
            :currentPage="currentPage"
            :paginationPageSize="paginationPageSize"
            @changed-page-size="paginationSetPageSize">
          </items-per-page>

          <list-action-drop-down
            v-if="isAnyRowsSelected"
            :options="defaultMultipleActionOptions"
            class="ml-3"
            @export="exportData()"
            @delete="confirmDeleteRecords()">
          </list-action-drop-down>
        </template>

        <template v-slot:header-right>
          <vs-button
            color="primary"
            type="border"
            :size="headerButtonsSizeByResolution"
            icon-pack="feather"
            icon="icon-plus"
            class="mr-3"
            @click="newItem">
            {{ $t("$General.AddNew") }}
          </vs-button>
        </template>
      </base-ag-grid-header>

      <vs-alert
        :active.sync="selectionPageAlert"
        class="text-center"
        color="grey-dark"
        closable icon-pack="feather"
        close-icon="icon-x">
        {{ $t('$SharedByManyModules.SelectedAllPageInListMsg', {
        count: rowsSelectedCount,
        entity: $tc(`$Entities.${entity}`, rowsSelectedCount) }) }}
        <a
          href="#"
          @click.prevent="setSelectedRowsAll()">
          {{ $t('$SharedByManyModules.SelectAllInListMsg', {
          count: count,
          entity: $tc(`$Entities.${entity}`, count) }) }}
        </a>
      </vs-alert>

      <export-alert-info
        :entity="$tc(`$Entities.${entity}`, 2)"
        :exporting.sync="exportingList"
        :exported.sync="exportedList"
        :fileUrl="urlFileExported"/>

      <ag-grid-vue
        :key="agGridKey"
        ref="agGridTable"
        :domLayout="domLayout"
        :style="gridStyle"
        :components="components"
        :frameworkComponents="frameworkComponents"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :column-types="columnTypes"
        :getRowNodeId="getRowNodeId"
        :autoParamsRefresh="true"
        rowSelection="multiple"
        :animateRows="true"
        :suppressRowClickSelection="true"
        rowModelType="infinite"
        :pagination="hasPagination"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="suppressPaginationPanel"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
        :cacheBlockSize="cacheBlockSize"
        :maxBlocksInCache="maxBlocksInCache"
        @selection-changed="onSelectionChanged"
        @grid-ready="onGridReady"
        @model-updated="onModelUpdate"
        @grid-size-changed="onGridSizeChanged"
        @first-data-rendered="onFirstDataRendered"
        @row-double-clicked="onRowDoubleClicked"
        @filter-changed="onFilterChanged"
        @pagination-changed="onPaginationChanged">
      </ag-grid-vue>

      <base-ag-grid-footer>
        <template v-slot:footer-right>
          <vs-pagination
            v-model="currentPage"
            :total="totalPages"
            :max="7"/>
        </template>
      </base-ag-grid-footer>

      <vs-popup
        :title="exportModalTitle"
        :active.sync="activeModalToExport">
        <transition name="zoom-fade">
          <export-json-to-excel
            v-if="showExportComponent"
            :columns="getColumnsToExport"
            :data="rowsSelected"
            @close="activeModalToExport=false"
            @export="onExport">
          </export-json-to-excel>
        </transition>
      </vs-popup>
    </div>

  </div>
</template>

<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import { mapActions } from 'vuex';

// Custom Components
import BaseAgGridHeader from '@/views/modules/_components/BaseAgGridHeader.vue';
import BaseAgGridFooter from '@/views/modules/_components/BaseAgGridFooter.vue';
import ItemsPerPage from '@/views/modules/_components/ItemsPerPage.vue';
import ExportJsonToExcel from '@/views/modules/_components/ExportJsonToExcel.vue';
import ListActionDropDown from '@/views/modules/_components/ListActionDropDown.vue';
import AgGridSelectionHeader from '@/views/modules/_components/AgGridSelectionHeader';
import ExportAlertInfo from '@/views/modules/_components/ExportAlertInfo.vue';
import AppServiceListCreateOrEdit from '@/views/modules/app-service/AppServiceListCreateOrEdit.vue';

// Cell Renderer
import CellRendererActions from '@/views/modules/_components/cell-renderer/SingleCellRendererActions.vue';

// Mixins
import commonListCreateOrEditWithAgGrid from '@/views/modules/_mixins/commonListCreateOrEditWithAgGrid';

/**
 * Component to view and update the app settings
 *
 * @module views/modules/app-service/AppServiceList
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string} [entity='AppService'] - Name of the entity associated
 * @vue-data {boolean} [modalsDelay=false] - delay to modals
 * @vue-data {Array.<Object>} columnDefs - Columns definition of ag grid to list
 * @vue-data {Object} components - Components to use to extend ag grid
 * @vue-data {Object} frameworkComponents - Framework components to extend ag grid
 */
export default {
  name: 'AppServiceList',
  components: {
    AppServiceListCreateOrEdit,
    ItemsPerPage,
    ListActionDropDown,
    ExportAlertInfo,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererActions,
    BaseAgGridHeader,
    BaseAgGridFooter,
    ExportJsonToExcel,
  },
  mixins: [commonListCreateOrEditWithAgGrid],
  data() {
    return {
      entity: this.$enums.Entity.APP_SERVICE,
      modalsDelay: 0,
      cloneDisabled: true,
      viewDisabled: true,
      exportCollectionFunction: this.exportAppServices,
      deleteRecordFunction: this.removeAppService,
      deleteRecordsFunction: this.removeAppServices,
      columnDefs: [
        {
          colId: 'name',
          headerName: this.$t('$General.Name'),
          field: 'name',
          filter: 'agTextColumnFilter',
          minWidth: 100,
          checkboxSelection: true,
          headerComponentParams: { checkboxSelection: true },
        },
      ],
      components: {
        CellRendererActions,
      },
      frameworkComponents: {
        agColumnHeader: AgGridSelectionHeader,
      },
    };
  },
  methods: {
    ...mapActions({
      fetchAllAppServices: 'appService/fetchAllAppServices',
      exportAppServices: 'appService/exportFile',
      fetchItem: 'appService/fetchAppService',
      removeAppService: 'appService/removeAppService',
      removeAppServices: 'appService/removeAppServices',
    }),
    async fetchAgGridData(params) {
      return this.fetchAllAppServices(params);
    },
    onRowDoubleClicked(params) {
      this.editRecord(params.node.data);
    },
  },
};
</script>
