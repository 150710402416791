import BaseService from '@/api/base.service';
import configApi from '@/api/config';
import grapePerksAppService from '@/api';

/**
 * AppServiceCountryService service api
 * @class AppServiceCountryService
 */
class AppServiceCountryService extends BaseService {
  constructor() {
    super({ baseUrl: configApi.baseRoutesUrls.appServiceCountries });
  }

  /**
   * Get one service country by service and country
   * @function getOneByServiceAndCountry
   * @param {string} serviceId - id of service of the service country
   * @param {string} countryIso2 - iso 2 code country of the service country
   * @return {Promise.<GrapePerksApiResponse | any>}
   */
  getOneByServiceAndCountry = ({ serviceId, countryIso2 }) => grapePerksAppService.get(
    `${this.baseUrl}/${serviceId}/${countryIso2}`,
  ).then((resp) => resp.data);
}

const singletonInstance = new AppServiceCountryService();

/**
 * app service country service api instance
 * @exports api/modules/app-service-country
 * */
export default singletonInstance;
