<template>
  <form @submit.prevent>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <vs-input
          v-model="model.name"
          class="w-full"
          :label="`${$t('$General.Name')}*`"
          :name="$t('$General.Name')"
          v-validate="'required|max:100'"
          data-vv-validate-on="input|blur"/>
        <span
          v-show="errors.has($t('$General.Name'))"
          class="text-danger text-sm">
          {{ errors.first($t('$General.Name')) }}
        </span>

        <vs-input
          v-model="model.key"
          :disabled="generateKeyFromName"
          class="w-full mt-3"
          :label="`${$t('$General.Key')}*`"
          :name="$t('$General.Key')"
          v-validate="'required|max:30'"
          data-vv-validate-on="input|blur"/>
        <span
          v-show="errors.has($t('$General.Key'))"
          class="text-danger text-sm">
          {{ errors.first($t('$General.Key')) }}
        </span>

        <vs-checkbox
          v-model="generateKeyFromName"
          class="mt-5">
          Generate key from name
        </vs-checkbox>
      </div>

      <div class="vx-col flex items-center w-full md:w-1/2 mt-3 md:mt-0">
        <div class="vx-row">
          <div class="vx-col w-full pt-1">
            <vs-checkbox
              v-model="model.pricesBasedOnCountry"
              class="ml-0"
              :disabled="!model.key">
              {{ $t('$AppServiceModule.PricesBasedOnCountry') }}
            </vs-checkbox>
          </div>
          <div class="vx-col w-full py-2">
            <vs-checkbox
              v-model="model.costsBasedOnUsd"
              class="ml-0">
              {{ $t('$AppServiceModule.CostsBasedOnUsd') }}
            </vs-checkbox>
          </div>
          <div class="vx-col w-full pb-1">
            <vs-checkbox
              v-model="model.packageBased"
              class="ml-0">
              {{ $t('$AppServiceModule.PackageBased') }}
            </vs-checkbox>
          </div>
        </div>
      </div>
    </div>

    <vs-tabs class="mt-3">
      <vs-tab :label="$t('$AppServiceModule.NewVersion')">
        <app-service-list-create-or-edit-packages
          v-if="!model.pricesBasedOnCountry || providerCostInfo.length > 0"
          :monthly-discount="monthlyDiscount"
          :usd-exchange-rate="usdExchangeRate"
          :provider-cost-info="providerCostInfo"
          :operation="operation"
          :selected-country.sync="countryNewVersion"
          :model="model"/>
        <vs-alert
          v-else
          color="danger"
          icon="new_releases"
          class="mb-3">
        <span>
          {{ $t('$AppServiceModule.ServiceNotSupportPriceCountryMsg') }}
        </span>
        </vs-alert>
      </vs-tab>
      <vs-tab
        v-if="operation === $enums.Operation.EDIT
        && ((Array.isArray(modelPayload.packages) && modelPayload.packages.length > 0)
        || (Array.isArray(modelPayload.items) && modelPayload.items.length > 0))"
        :label="$t('$AppServiceModule.CurrentVersion')">
        <div class="tab-text">
          <div class="vx-row mt-3">
            <div class="vx-col w-full md:w-1/3 pt-1">
              <vs-checkbox
                :value="modelPayload.pricesBasedOnCountry"
                class="ml-0"
                disabled>
                {{ $t('$AppServiceModule.PricesBasedOnCountry') }}
              </vs-checkbox>
            </div>
            <div class="vx-col w-full md:w-1/3 py-2">
              <vs-checkbox
                :value="modelPayload.costsBasedOnUsd"
                class="ml-0"
                disabled>
                {{ $t('$AppServiceModule.CostsBasedOnUsd') }}
              </vs-checkbox>
            </div>
            <div class="vx-col w-full md:w-1/3 pb-1">
              <vs-checkbox
                :value="modelPayload.packageBased"
                class="ml-0"
                disabled>
                {{ $t('$AppServiceModule.PackageBased') }}
              </vs-checkbox>
            </div>
          </div>
          <app-service-list-create-or-edit-packages
            :monthly-discount="modelPayload.monthlyDiscount"
            :usd-exchange-rate="modelPayload.usdExchangeRate"
            :provider-cost-info="providerCostInfo"
            :operation="operation"
            :model="modelPayloadLocal"
            :selected-country.sync="countryCurrentVersion"
            only-show/>
        </div>
      </vs-tab>
    </vs-tabs>

    <base-form-footer-action-buttons
      :disabled-save="!validateForm"
      @save="save(model.toSavePayload())"
      @cancel="$emit('close')"/>
  </form>
</template>

<script>
import { mapActions } from 'vuex';

// Custom components
import BaseFormFooterActionButtons from '@/views/modules/_components/BaseFormFooterActionButtons.vue';
import AppServiceListCreateOrEditPackages from '@/views/modules/app-service/AppServiceListCreateOrEditPackages.vue';

// Mixins
import singleCreateOrEdit from '@/views/modules/_mixins/singleCreateOrEdit';

// Services
import appServiceService from '@/api/modules/app-service.service';
import appSettingsService from '@/api/modules/app-settings.service';

// Constructors
import AppServiceConstructor from '@/views/modules/app-service/app-service.constructor';

/**
 * Component to create or edit app services
 *
 * @module views/modules/app-service/AppServiceListCreateOrEdit
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object} model - model to save
 * @vue-data {Object} modelPayloadLocal - copy of model payload
 * @vue-data {Number} monthlyDiscount - monthly discount for packages
 * @vue-data {Number} usdExchangeRate - usd exchange rate for services
 * @vue-data {Object | null} countryCurrentVersion - selected country for current version of service
 * @vue-data {Object | null} countryNewVersion - selected country for new version of service
 * @vue-data {Object[]} providerCostInfo - provider cost by countries
 * @vue-computed {boolean} verifyProperties - verify is properties to save the service are valid
 * @vue-event {void} model.packageBased - watch to generate default packages
 * @vue-event {void} model.pricesBasedOnCountry - watch to get provider costs
 * @vue-event {void} created - hook to get app settings and provider costs if is necessary
 * @vue-event {void} fetchItem - fetch one app service. Mapped from store
 * @vue-event {void} getAppSettings - retrieve the app settings
 * @vue-event {void} getProviderCost - retrieve the provider costs
 */
export default {
  name: 'AppServiceListCreateOrEdit',
  components: {
    BaseFormFooterActionButtons,
    AppServiceListCreateOrEditPackages,
  },
  mixins: [singleCreateOrEdit],
  data() {
    return {
      model: new AppServiceConstructor(this.operation, this.modelPayload),
      modelPayloadLocal: new AppServiceConstructor(this.operation, this.modelPayload),
      monthlyDiscount: 0,
      usdExchangeRate: 1,
      countryCurrentVersion: null,
      countryNewVersion: null,
      providerCostInfo: [],
      addItemFunction: this.addAppService,
      editItemFunction: this.editAppService,
      generateKeyFromName: true,
    };
  },
  computed: {
    verifyProperties() {
      return this.model.name && this.model.key
        && (!this.model.pricesBasedOnCountry || this.providerCostInfo.length > 0);
    },
    keyFromName() {
      return this.model.name.toLowerCase().replace(/ /g, '-');
    },
  },
  watch: {
    generateKeyFromName(val) {
      if (val) {
        this.model.key = this.keyFromName;
      }
    },
    'model.name': function () {
      if (this.generateKeyFromName) {
        this.model.key = this.keyFromName;
      }
    },
    'model.packageBased': function (packageBased) {
      if (packageBased && this.model.packages.length === 0) {
        this.model.generatePackages();
      }
    },
    'model.pricesBasedOnCountry': function (val) {
      if (val) {
        this.getProviderCost();
      }
    },
  },
  created() {
    this.getAppSettings();

    if (this.operation === this.$enums.Operation.EDIT) {
      if (this.model.pricesBasedOnCountry) {
        this.getProviderCost();
      }
    }
  },
  methods: {
    ...mapActions({
      fetchItem: 'appService/fetchAppService',
      addAppService: 'appService/addAppService',
      editAppService: 'appService/editAppService',
    }),
    async getAppSettings() {
      const resp = await appSettingsService.get();
      const appSettings = resp.data;
      this.monthlyDiscount = appSettings.monthlyDiscountServices;
      this.usdExchangeRate = appSettings.usdExchangeRate;
    },
    async getProviderCost() {
      this.$vs.loading({ type: 'radius' });
      const resp = await appServiceService.getProviderCost(this.model.key);
      this.providerCostInfo = resp.data;
      this.$vs.loading.close();
    },
  },
};
</script>
